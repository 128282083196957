
























import { Component, Mixins } from "vue-property-decorator";

// Utilities
import { AuthMixin } from "@mixins";

@Component
export default class Home extends Mixins(AuthMixin) {}
